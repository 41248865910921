<template>
  <payment-common :alt-payer="false">
    <template #header>
      <b-alert variant="primary" v-if="cardAuthActive" show>Customer is doing card authentication</b-alert>
      <b-alert variant="primary" v-else-if="processing" show>Processing request</b-alert>
      <b-alert variant="warning" v-if="error" show dismissible>{{ error.message }}</b-alert>
      <b-alert variant="success" v-if="internalValue.finalised" show dismissible>Payment successfully processed</b-alert>
    </template>
    <div class="my-2" v-if="state && state.cardDetails && state.cardDetails.firstSix && state.cardDetails.lastFour">
            <component :is="icons[state.cardDetails.brand]" v-if="icons[state.cardDetails.brand]" style="height: 1em; width: 2em;" />
            <span v-else v-text="state.cardDetails.brand" />
            {{ state.cardDetails.firstSix }}••••••{{ state.cardDetails.lastFour }} <span v-if="state.cardDetails.expMonth && state.cardDetails.expYear" class="text-muted">{{ state.cardDetails.expMonth }} / {{ state.cardDetails.expYear }}</span>
    </div>
    <!-- don't show link and client info if successful (finalised) -->
    <template v-if="!internalValue.finalised">
      <b-alert show v-if="waitingForClientToVisit">Link sent. Awaiting client to open it.</b-alert>

      <b-alert show v-if="clientEnteringCardDetails">Client is entering card details</b-alert>
      <b-alert show v-else-if="clientVisitingRemotePage">Client is on remote page</b-alert>
      <b-button v-else variant="primary" :disabled="processing" @click="sendLink">{{ internalValue.remotePaymentSessionId ? 'Resend' : 'Send' }} the payment link via SMS to {{ $store.state.familyInformation.mobilePhone }}</b-button>
    </template>

    <template #footer v-if="!internalValue.finalised">
      <spinner class="mt-3" v-if="processing" color="rgb(24, 108, 149)" />
      <!-- <b-button class="float-right ml-3" variant="primary" @mouseover="$v.paidBy.$touch" @click="processPayment" :disabled="!canEdit || !cardDetailsOk">Process payment for {{ $n(amount, 'currency') }}</b-button> -->
    </template>
    <!-- <div>
      {{ state }}
    </div> -->
  </payment-common>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, computed } from 'vue'
import { RecurlyRemoteCreditCardPayment } from '@iris/store/payments/types'
import paymentMixins from './mixin'
import { useRecurlyRemote } from './recurly'
import { IAccount, PaymentType } from '@iris/nestjs-interfaces-recurly'
import spinner from 'vue-spinner/src/RiseLoader.vue'
import { IrisGetters, IrisStore } from '../../store/types'
import brandIcons from '@iris/assets/cardLogos'


const mixin = paymentMixins<RecurlyRemoteCreditCardPayment>({ includeCanEdit: false })
export default defineComponent({
  // extends: mixin,
  mixins: [mixin],
  components: {
    // this is just html structure not specific to
    spinner
  },
  methods: {
    remove () {
      return this.signalDeletion().then(() => this.$emit('remove'))
    }
  },
  computed: {
    canRemoveEnabled (): boolean {
      return !this.internalValue.finalised
    }
  },
  setup () {
    const currentInstance = getCurrentInstance()!
    const instance = currentInstance.proxy as unknown as InstanceType<typeof mixin>
    return {
      icons: brandIcons,
      ...useRecurlyRemote({
      paymentTypeProps: computed<Partial<IAccount>>(() => {
        return {
          type: PaymentType.SINGLE,
          monthsInitialPayment: (instance.$store.getters as IrisGetters).currentNewPricingPlan.upfrontPayment?.period.asMonths(),
          sessionCostOverride: (instance.$store as IrisStore).state.overrideExtraSessionsCost ?? undefined,
          addons: (instance.$store as IrisStore).getters.currentNewPricingPlan.addons
        }
      }),
      value: computed<RecurlyRemoteCreditCardPayment>({
        get () {
          return instance.internalValue
        },
        set (v) {
          instance.internalValue = v
        }
      })
    })
  }
  } // end setup
})
</script>

