<template functional>
  <b-card bg-variant="light" style="margin-bottom: 1rem" :header="parent.title">
    <slot name="header" />
    <reg-form-input label="Amount" prepend="£" min="0" max="15000"
        :formatter="parent.moneyNumberOnly"
        :value="parent.moneyNumberOnly(parent.amount)"
        @change="parent.updateAmount"
        :disabled="!parent.canEdit || parent.internalValue.amountReadonly"
        :$v="parent.$v.amount"
         lazy-formatter field-name="Amount"
         :messages="{
           minValue: `Please enter a value greater than ${parent.$i18n.n(parent.internalValue.minAmount, 'currency')}`,
           maxValue: `Maximum amount allowed is ${parent.$i18n.n(parent.internalValue.maxAmount, 'currency')}`
         }"
      >
      <template #append v-if="$slots.append">
        <slot name="append" />
      </template>
    </reg-form-input>
    <template v-if="props.altPayer">
      <reg-form-radio :$v="parent.$v.paidBy" :disabled="!parent.canEdit" :checked="parent.internalValue.paidBy" @input="parent.updatePaidBy" :label="props.refund ? 'Paid to' : (parent.stripeElementsOptions ? 'Card holder' : 'Paid by')"
        :options="parent.depositPayerNameOptions" :description="props.refund ? 'Who is getting this refund' : 'Who is making this payment'" :messages="{
          infoValidForStripePayment: 'The information entered for Payer is incomplete'
        }" />
        <!-- This from is only to be visible to the first payment who chooses the payer type -->
      <alt-payer-information-inner-form bg-variant="default" border-variant="secondary" :has-mobile="true" :loqate-email-message="parent.loqateMessages.depositPayerEmail" v-if="parent.showAltPayerDepositForm" :description="`Details of the person paying the initial payment for the ${parent.$store.getters.shortCompanyName} Programme`" header-title="Initial Payment Payer" store-key="finance.depositAltPayerInfo" :$v="parent.depositAltPayerValidations" />
    </template>
    <slot />
    <template #footer v-if="parent.canRemove || $slots.footer">
      <slot name="footer" />
      <b-button class="float-right" v-if="parent.canRemove" :disabled="!parent.canRemoveEnabled" @click="parent.$bvModal.show(parent.safeId('deleteConfirmation'))" variant="danger">remove</b-button>
    </template>
    <b-modal :id="parent.safeId('deleteConfirmation')" ok-variant="danger" title="Confirmation" ok-title="Remove" @ok="parent.remove()">
      <p>Remove {{parent.title}}?</p>
    </b-modal>
  </b-card>
</template>

<script>
  export default {
    functional: true,
    props: {
      altPayer: {
        type: Boolean,
        default: true
      }
    }
  }
</script>