<template>
  <b-card class="student-card">
    <template v-if="!onlyCourseChange">
      <b-form-row>
        <label class="col-md-2 col-lg-2 control-label">Student {{ index + 1 }}</label>
        <reg-form-input class="col-sm-5 col-md-5 col-lg-2" :$v="$v.firstName" v-model.trim.lazy="firstName" field-name="First Name" />
        <reg-form-input class="col-sm-5 col-md-5 col-lg-2" :$v="$v.lastName" v-model.trim.lazy="lastName" field-name="Last Name" />
        <reg-form-radio buttons :$v="$v.gender" v-model="gender" field-name="Gender" :options="['M','F']" />
        <reg-form-input class="col-sm-10 col-md-4 col-lg-3" :$v="$v.dob" :value="dob" :max="todaysDate" :messages="{minValue: 'Please enter a valid date', maxValue: 'Please choose a date in the past'}" @change="parseAndUpdateDOB" type="date" field-name="Date of Birth" />
        <div v-if="!noAddOrRemove && index > 0" class="col-sm-1">
          <button class="btn btn-danger btn-sm" @click="remove">Remove</button>
        </div>
      </b-form-row>
      <b-form-row>
        <reg-form-checkbox v-model="homeSchooling" class="offset-md-2 offset-lg-2" fieldName="Home Schooled" />
      </b-form-row>
    </template>
    <b-form-row v-else>
      <p>{{ index + 1 }}. {{ firstName }} {{ lastName }} ({{ gender }}) {{ dobFormatted }}</p>
    </b-form-row>
    <b-form-row>
      <child-course class="col-sm-3" :$v="$v.courses.maths" v-if="$store.getters.selectedCoursesLists.maths.length" :index="index" subject="maths" name="Mathematics" />
      <child-course class="col-sm-3" :$v="$v.courses.mathsGCSE" v-if="$store.getters.selectedCoursesLists.mathsGCSE.length" :index="index" subject="mathsGCSE" name="GCSE Revision" />
      <child-course class="col-sm-3" :$v="$v.courses.mathsN5" v-if="$store.getters.selectedCoursesLists.mathsN5.length" :index="index" subject="mathsN5" name="N5 Revision" />
      <child-course class="col-sm-3" :$v="$v.courses.literacy" v-if="$store.getters.selectedCoursesLists.literacy.length" :index="index" subject="literacy" name="Literacy" />
      <child-course class="col-sm-3" :$v="$v.courses.reading" v-if="$store.getters.selectedCoursesLists.reading.length" :index="index" subject="reading" name="Learning to Read" />
    </b-form-row>
  </b-card>
</template>

<script>
import childCourse from './ChildCourse'
import dobParsing from './mixins/dobParsing'
import moment from 'moment'

export default {
  components: { childCourse },
  mixins: [dobParsing],
  props: {
    index: {
      type: Number,
      required: true
    },
    $v: {
      type: Object,
      required: true
    },
    onlyCourseChange: {
      type: Boolean,
      default: false
    },
    noAddOrRemove: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    todaysDate () {
      return this.$store.getters.moment().format('YYYY-MM-DD')
    },
    // modified mapFields with index as part of the getters and setters
    ...['firstName', 'lastName', 'gender', 'dob', 'homeSchooling'].reduce((prev, key) => {
      prev[key] = {
        get () {
          return this.$store.getters.getField(`children[${this.index}].${key}`)
        },
        set (value) {
          this.$store.commit('updateField', { path: `children[${this.index}].${key}`, value })
        }
      }
      return prev
    }, {}),
    dobFormatted () {
      return moment(this.dob, ['YYYY-MM-DD']).format('D/M/YYYY')
    }
  },
  methods: {
    remove () {
      this.$store.commit('removeChild', this.index)
    }
  }
}
</script>

<style lang="scss">
  .student-card {
    margin-bottom: 1rem;
  }
</style>
