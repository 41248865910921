<template>
  <payment-common :alt-payer="false">
    <template #header>
      <b-alert variant="primary" v-if="cardAuthActive" show>Customer is doing card authentication</b-alert>
      <b-alert variant="primary" v-else-if="processing" show>Processing request</b-alert>
      <b-alert variant="warning" v-if="error" show dismissible>{{ error.message }}</b-alert>
      <b-alert variant="success" v-if="internalValue.finalised" show dismissible>Card details successfully saved</b-alert>
    </template>
    <deferred-deposit-field />
    <div class="my-2" v-if="state && state.cardDetails && state.cardDetails.firstSix && state.cardDetails.lastFour">
            <component :is="icons[state.cardDetails.brand]" v-if="icons[state.cardDetails.brand]" style="height: 1em; width: 2em;" />
            <span v-else v-text="state.cardDetails.brand" />
            {{ state.cardDetails.firstSix }}••••••{{ state.cardDetails.lastFour }} <span v-if="state.cardDetails.expMonth && state.cardDetails.expYear" class="text-muted">{{ state.cardDetails.expMonth }} / {{ state.cardDetails.expYear }}</span>
    </div>
    <!-- don't show link and client info if successful (finalised) -->
    <template v-if="!internalValue.finalised">
      <b-alert show v-if="waitingForClientToVisit">Link sent. Awaiting client to open it.</b-alert>

      <b-alert show v-if="clientEnteringCardDetails">Client is entering card details</b-alert>
      <b-alert show v-else-if="clientVisitingRemotePage">Client is on remote page</b-alert>
      <b-button v-else variant="primary" :disabled="processing || $v.dueOn.$invalid" @click="sendLink">{{ internalValue.remotePaymentSessionId ? 'Resend' : 'Send' }} the link to collect card details via SMS to {{ $store.state.familyInformation.mobilePhone }}</b-button>
    </template>

    <template #footer v-if="!internalValue.finalised">
      <spinner class="mt-3" v-if="processing" color="rgb(24, 108, 149)" />
      <!-- <b-button class="float-right ml-3" variant="primary" @mouseover="$v.paidBy.$touch" @click="processPayment" :disabled="!canEdit || !cardDetailsOk">Process payment for {{ $n(amount, 'currency') }}</b-button> -->
    </template>
    <!-- <div>
      {{ state }}
    </div> -->
  </payment-common>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, computed } from 'vue'
import { RecurlyRemoetDeferredPayment } from '@iris/store/payments/types'
import paymentMixins from './mixin'
import { IrisStore } from '@iris/store/types'
import deferredMixins from './deferredMixins'
import { IAccount, PaymentType } from '@iris/nestjs-interfaces-recurly'
import spinner from 'vue-spinner/src/RiseLoader.vue'
import { TIMEZONE } from '@iris/constants'
import moment from 'moment-timezone'
import { useRecurlyRemote } from './recurly'
import brandIcons from '@iris/assets/cardLogos'


const mixin = paymentMixins<RecurlyRemoetDeferredPayment>({ includeCanEdit: false })
export default defineComponent({
  extends: mixin,
  mixins: [deferredMixins],
  components: {
    // this is just html structure not specific to
    spinner
  },
  methods: {
    remove () {
      return this.signalDeletion().then(() => this.$emit('remove'))
    }
  },
  computed: {
    canRemoveEnabled (): boolean {
      return !this.internalValue.finalised
    }
  },
  setup () {
    const currentInstance = getCurrentInstance()!
    const instance = currentInstance.proxy as unknown as InstanceType<typeof mixin>
    const store = instance.$store as IrisStore
    const { public: { recurlyPublicKey } } = useRuntimeConfig()

    return {
      icons: brandIcons,
      ...useRecurlyRemote({
        paymentTypeProps: computed<Partial<IAccount>>(() => {
          return {
            type: PaymentType.DEFERRED,
            deferredDate: moment.tz(instance.internalValue.dueOn!, TIMEZONE).startOf('day').toDate(),
            sessionCostOverride: (instance.$store as IrisStore).state.overrideExtraSessionsCost ?? undefined,
            addons: (instance.$store as IrisStore).getters.currentNewPricingPlan.addons
          }
        }),
        value: computed<RecurlyRemoetDeferredPayment>({
          get () {
            return instance.internalValue
          },
          set (v) {
            instance.internalValue = v
          }
        })
      })
    }
  }
})
</script>

