<template>
  <div>
    <b-form-group :state="paymentAmountState" label-cols="0">
      <!-- The list of deposits-->
      <b-form-group :state="$v.$each[index].$dirty ? !$v.$each[index].$error : null" v-for="(deposit, index) in paymentsWithDollars" :key="`${deposit.type}${index}`">
        <component
          :class="{
            'payments-is-invalid': paymentAmountState === false || $v.$each[index].$error
          }"
          class="mb-0"
          :index="index"
          :value="deposit"
          :loqate-messages="loqateMessages"
          :$v="$v.$each[index]"
          @remove="removePayment({ index })"
          @changed="updatePayment({ index, payment: $event })"
          :is="depositComponentFor(deposit.type)"
          :show-alt-payer-deposit-form="deposit === firstPaymentWithDepositAltPayer"
          :deposit-alt-payer-validations="depositAltPayerValidations"
          />
        <div slot="invalid-feedback">Please complete or remove this payment</div>
      </b-form-group>
      <div slot="invalid-feedback">The amounts must add up to {{ $n(paymentAmountRequiredNow, 'currency') }}</div>
    </b-form-group>
    <!-- The buttons to add new deposits -->
    <b-form-group v-if="paymentOwingInCents !== 0" :state="paymentLengthState" :label="`Add ${title}`" label-cols="4" description="Use the above buttons to create and process a payment">
      <b-button-group>
        <b-button v-b-tooltip.hover :title="$t(`payments.hint.${type}`)" v-for="(type, index) in paymentTypesAvailable" :key="index" :class="{'payments-is-invalid': paymentLengthState === false}" variant="outline-primary" @click="addPayment({ type })">
          {{ $t(`payments.title.${type}`) }}
        </b-button>
      </b-button-group>
      <div slot="invalid-feedback">You need to add at least one payment</div>
    </b-form-group>
  </div>
</template>

<script lang="ts">
import CashPayment from './CashPayment.vue'
import KlarnaPayment from './KlarnaPayment.vue'
import ChequePayment from './ChequePayment.vue'
import CreditCardPayment from './CreditCardPayment.vue'
import DeferredDepositPayment from './DeferredDepositPayment.vue'
import StripeCreditCardPayment from './StripeCreditCardPayment.vue'
import StripeDeferredPayment from './StripeDeferredPayment.vue'
import StripePaymentPlan from './StripePaymentPlan.vue'
import ManualRefund from './ManualRefund.vue'
// import RemotePayment from './RemotePayment.vue'
import RecurlyCreditCardPayment from './RecurlyCreditCardPayment.vue'
import RecurlyRemoteCreditCardPayment from './RecurlyRemoteCreditCardPayment.vue'
import RecurlyDeferredPayment from './RecurlyDeferredPayment.vue'
import RecurlyRemoteDeferredPayment from './RecurlyRemoteDeferredPayment.vue'
import RecurlyPaymentPlan from './RecurlyPaymentPlan.vue'

import Vue, { VueConstructor } from 'vue'
import { DropDownOption } from '../../store/types'
import { PaymentModuleGetters, PaymentWithDollars, PaymentTypes } from '../../store/payments/types'

import { mapActions, mapGetters } from 'vuex'
import { PropValidator } from 'vue/types/options'

const componentMap:Record<PaymentTypes, VueConstructor> = {
  'cash': CashPayment,
  'cheque': ChequePayment,
  'exemplarsite-card': CreditCardPayment,
  'deferred': DeferredDepositPayment,
  'creditcard-stripe': StripeCreditCardPayment,
  'deferred-stripe': StripeDeferredPayment,
  'payment-plan-stripe': StripePaymentPlan,
  'manual-refund': ManualRefund,
  // 'remote': RemotePayment,
  'creditcard-recurly': RecurlyCreditCardPayment,
  'creditcard-recurly-remote': RecurlyRemoteCreditCardPayment,
  'deferred-recurly': RecurlyDeferredPayment,
  'deferred-recurly-remote': RecurlyRemoteDeferredPayment,
  'payment-plan-recurly': RecurlyPaymentPlan,
  'klarna': KlarnaPayment
}

export default Vue.extend({
  props: {
    $v: {
      type: Object,
      default: () => {}
    },
    depositAltPayerValidations: {
      type: Object,
      required: false
    },
    paymentTypes: {
      type: Array,
      required: false,
      default: ():PaymentTypes[] => [
        'creditcard-stripe',
        'deferred-stripe',
        'payment-plan-stripe',
        'cash',
        'cheque',
        'exemplarsite-card',
        'deferred',
        'manual-refund',
        'creditcard-recurly',
        'creditcard-recurly-remote',
        'deferred-recurly',
        'deferred-recurly-remote'
      ]
    } as PropValidator<PaymentTypes[]>,
    title: {
      type: String,
      required: false,
      default: 'Payment'
    },
    loqateMessages: {
      type: Object,
      required: true
    }
  },
  computed: {
    /** filter the payment types to show refund if necessary or available */
    paymentTypesAvailable (): PaymentTypes[] {
      return this.paymentTypes.filter(t => {
        // if payment amount isn't fixed then show everything
        if (this.paymentOwingInCents === null) {
          return true
        }
        if (this.paymentOwingInCents > 0 && t === 'manual-refund') {
          // remove refund if +ve amount
          return false
        } else if (this.paymentOwingInCents < 0 && t !== 'manual-refund') {
          // remove everything else if -ve amount
          return false
        }
        return true
      })
    },
    paymentLengthState (): boolean | null {
      return this.$v.$dirty ? this.$v.minLength : null
    },
    paymentAmountState (): boolean | null {
      return this.$v.$dirty ? this.$v.amount : null
    },
    ...mapGetters(['paymentAmountRequiredNow']) as {
      paymentAmountRequiredNow: () => number
    },
    ...mapGetters('payments', [
      'paymentsWithDollars',
      'firstPaymentWithDepositAltPayer',
      'paymentOwingInCents'
    ]) as {
      paymentsWithDollars: () => PaymentWithDollars[],
      firstPaymentWithDepositAltPayer: () => Readonly<PaymentWithDollars> | undefined,
      paymentOwingInCents: () => number | null
    }
  },
  methods: {
    ...mapActions('payments', ['addPayment', 'updatePayment', 'removePayment']),
    depositComponentFor (paymentType: PaymentTypes): VueConstructor {
      return componentMap[paymentType]
    }
  },
  mounted (this: any) {
    if (this.paymentTypesAvailable.length === 1 && !this.paymentsWithDollars.length) {
      return this.addPayment({ type: this.paymentTypesAvailable[0] })
    }
  }
})
</script>

<style scoped>
  .payments-is-invalid {
    border-color: #dc3545;
  }
</style>
