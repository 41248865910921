<template>
  <div v-if="paymentMethod && paymentAmountRequiredNow > 0">
    <h4>{{ title }}</h4>
    <!-- This is the plain text version of the deposit box for PAYG determined by these options-->
    <reg-form-input label="Payment required today" plaintext :value="money(paymentAmountRequiredNow)" />
    <payments :title="title" :loqate-messages="loqateMessages" :$v="$v.payments" :deposit-alt-payer-validations="$v.depositAltPayerInfo" :payment-types="paymentTypes" />
  </div>
</template>

<script>
import financeMixins from '../mixins/financeMixins'
import { mapFields } from 'vuex-map-fields'
import { mapState, mapGetters } from 'vuex'
import _compact from 'lodash/compact'
import Payments from './payments/index'

import PaygRevisionTerm from './PaygRevisionTerm'

export default {
  mixins: [ financeMixins ],
  components: { PaygRevisionTerm, Payments },
  props: {
    $v: {
      type: Object,
      required: true
    },
    loqateMessages: {
      type: Object,
      required: true
    }
  },
  watch: {
    depositOptions: {
      handler (options) {
        if (options.length === 1) {
          this.monthsDeposit = options[0].value
        } else if (!options.some(option => option.value === this.monthsDeposit)) {
          this.monthsDeposit = null
        }
      },
      immediate: true
    }
  },
  computed: {
    depositOptions () {
      const monthOptions = [
        { text: 'One Month', value: 1 },
        { text: 'Two Months', value: 2 },
        { text: 'Three Months', value: 3 }
      ]
      if (this.paymentMethod === 'APS_SUBSCRIPTION') {
        monthOptions.push(
          { text: 'Six Months', value: 6 },
          { text: 'Twelve Months', value: 12 }
        )
      }
      if (this.$store.getters.isSubscriptionPlanDec2021) {
        return [{ text: 'One Month', value: 1 }]
      }
      // if in resigning mode don't filter this list as refund type is available
      if (this.documentationOnlyMode) {
        return monthOptions
      }
      if (this.hasDeferred || this.$store.state.bf90hack) {
        return monthOptions.slice(0, 1) // only show 1 month
      }
      return monthOptions.filter(option => Math.floor(this.paygDepositAmountFn(option.value) * 100) >= this.totalAmountPaidInCents)
    },
    immediateNoticeProvided: {
      get () {
        return this.$store.getters.immediateNoticeProvided
      },
      set (value) {
        this.$store.commit('updateField', {
          path: 'finance.immediateNoticeProvided',
          value
        })
      }
    },
    title () {
      return 'Initial Subscription'
    },
    ...mapState({
      familyInformation: state => state.familyInformation,
      address: state => state.address,
      createAccountResult: state => (state.createAccountResult || {}),
      consultant: state => state.consultant,
      branchOffice: state => state.branchOffice,
      documentationOnlyMode: state => state.documentationOnlyMode
    }),
    ...mapGetters([
      'saleValue',
      'depositPayerNameOptions',
      'paygRevisionPricing',
      'paymentAmountRequiredNow',
      'paygDepositAmountFn',
      'currentNewPricingPlan'
    ]),
    ...mapGetters('payments', ['totalAmountPaidInCents', 'paymentsWithDollars', 'deferredPaymentAvailable']),
    hasDeferred () {
      return this.paymentsWithDollars.some((p) => p.deferred)
    },
    hasCashPayment () {
      return this.paymentsWithDollars.some((p) => p.type === 'cash')
    },
    /** @returns {import('../store/payments/types').PaymentTypes[]} */
    paymentTypes () {
      if (this.currentNewPricingPlan && this.currentNewPricingPlan.initialPaymentTypes) {
        return this.currentNewPricingPlan.initialPaymentTypes
      }
      // only allow a single deferred payment
      const showDeferred = !this.hasDeferred && ((
        this.deferredPaymentAvailable && this.monthsDeposit === 1 && this.paymentsWithDollars.length === 0
      ) || (
        this.pmIn('LTL')
      ))
      return [
        ...(this.paymentMethod !== 'SP30' ? ['creditcard-recurly', 'creditcard-recurly-remote'] : []),
        ...((this.paymentMethod === 'CASH' || this.paymentMethod === 'SP30' || (this.paymentMethod === 'LTS10' && this.$store.state.promoCodes.some(c => /-\d+$/.test(c)))) && this.paymentsWithDollars.length === 0
          ? ['payment-plan-recurly']
          : []),
        ...(showDeferred ? ['deferred-recurly', 'deferred-recurly-remote'] : []),
        // remove cash option for double initial payment
        ...(this.paymentMethod === 'GCSE' ? [] : ['cash']),
        ...(this.$store.state.regModelMap.INSTITUTECODE === 'EXEDU' ? ['exemplarsite-card'] : []),
        // 'remote',
        // 'creditcard-recurly',
        // 'deferred-recurly', // TODO FIX LOGIC TO SHOW HIDE ETC....
        // 'payment-plan-recurly',
        ...((showDeferred && this.pmIn('LTL')) ? ['deferred'] : []),
        ...(this.documentationOnlyMode ? ['manual-refund'] : []) // only allow refund type for documentation generation mode
      ]
    },
    ...mapFields([
      'finance.monthsDeposit',
      'singleMonthInitialSubscription'
    ])
  },
  methods: {
    moneyNumberOnly (inputNumber) {
      return this.$n(inputNumber, 'money')
    }
  }
}
</script>
