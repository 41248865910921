import { required, maxLength, or, requiredIf, email, sameAs, helpers, CustomRule, ValidationRule } from 'vuelidate/lib/validators'
import { regex } from 'vuelidate/lib/validators/common'
import moment, { MomentInputObject } from 'moment'
import Vue from 'vue'
import { IrisState, IrisGetters, CourseTypes, IAddressInfo, IPersonInfo } from '@iris/store/types'
import LoqateApi, { LoqateEmailValidationResponse } from '@iris/loqateApi'
import { Payment, PaymentWithDollars, PaymentTypes, StripePaymentPlan } from '@iris/store/payments/types'
import { CourseSelectionsMixinComputedType } from '@iris/mixins/courseSelectionsMixin'
import { ValidationProperties } from 'vue/types/vue'
import { Validation } from 'vuelidate'

// taken from https://en.wikipedia.org/wiki/Postcodes_in_the_United_Kingdom
const UK_POSTCODE_REGEX = /^([A-Za-z][A-Ha-hK-Yk-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/

type CustomAsyncRule = (value: any, parentVm?: any) => Promise<ReturnType<CustomRule>>
type ValidationRuleFn = (...args: any[]) => ValidationRule
type PartRuleTreeRule = CustomAsyncRule | CustomRule | ValidationRule | ValidationRuleFn
type PartRuleTree = Record<string, PartRuleTreeRule | Record<string, PartRuleTreeRule | Record<string, PartRuleTreeRule | Record<string, PartRuleTreeRule>>>>
interface DataTree extends Vue {
  finance: IrisState['finance'];
  loqateMessages: Record<string, string>;
  $loqate: LoqateApi
}

export function courseSelections (this: DataTree) {
  let validations: PartRuleTree = {
    totalModuleCount: {
      minModules: or(
        function (this: DataTree, minModules: number, courseSelections: CourseSelectionsMixinComputedType) {
          // leave out revision courses
          return minModules >= (this.$store.getters as IrisGetters).minimumModules
        }, // either 5 paid modules which is 6 without revision
        function (_minModules: number, courseSelections: CourseSelectionsMixinComputedType) {
          // only allow revision on it's own for CASH & PAYG TYPES
          return courseSelections.totalModuleCount === 0 &&
            courseSelections.moduleCount.mathsRevision === 1
        } // 1 module and it can only be revision
      ),
      noRevisionOnly (this: DataTree, _minModules: number, courseSelections: CourseSelectionsMixinComputedType) {
        return true
        // // not applicable for PAYG & CASH return true
        // if (this.finance.paymentMethod !== 'LTL' && this.finance.paymentMethod !== 'APS') return true
        // // don't allow revision on it's own for LTL & APS TYPES
        // return !(courseSelections.totalModuleCount === 0 &&
        //   courseSelections.moduleCount.mathsRevision === 1)
      },
      doNotAllowSevenModules (this: DataTree, minModules: number): boolean {
        return minModules !== ((this.$store.getters as IrisGetters).minimumModules + 1)
      },
      mustHaveMinTwoMaths (this: DataTree, _minModules: number, courseSelections: CourseSelectionsMixinComputedType): boolean {
        return courseSelections.moduleCount.mathsWithoutRevision !== 1
      },
      mustHaveMinTwoLiteracy (this: DataTree, _minModules: number, courseSelections: CourseSelectionsMixinComputedType): boolean {
        return courseSelections.moduleCount.literacy !== 1
      }
    }
  }

  if ((this.$store.getters as IrisGetters).paygRevisionPricing) {
    validations.monthsTerm = {
      required
    }
  }
  return validations
}

export function payments (this: DataTree) : PartRuleTree {
  return {
    // minLength: minLength(1),
    minLength: function (paymentsArray: PaymentWithDollars[]) {
      return (this.$store.getters as IrisGetters).paymentAmountRequiredNow === 0 ||  paymentsArray.length > 0 
    },
    amount: function (this: DataTree, paymentsArray: PaymentWithDollars[]) {
      let amountPayableToday = (this.$store.getters as IrisGetters)['payments/depositPayableInCents']
      let totalAmountPaid = (this.$store.getters as IrisGetters)['payments/totalAmountPaidInCents']
      if (amountPayableToday > 0) {
        return amountPayableToday === totalAmountPaid
      } else {
        return true // no amount really payable today not enforced
      }
    },
    $each: {
      finalised: {
        noStripeInProgressPayments: function (this: DataTree, value: undefined, payment: PaymentWithDollars) {
          return (['creditcard-stripe', 'deferred-stripe', 'payment-plan-stripe', 'creditcard-recurly', 'deferred-recurly', 'deferred-recurly-remote', 'payment-plan-recurly', 'creditcard-recurly-remote'] as PaymentTypes[]).indexOf(payment.type) === -1 || !!payment.finalised
        }
      },
      paidBy: {
        required,
        infoValidForStripePayment (this: DataTree, value: PaymentWithDollars['paidBy'], payment: PaymentWithDollars) {
          // only applies for stripe payments
          if ((['creditcard-stripe', 'deferred-stripe', 'payment-plan-stripe', 'creditcard-recurly', 'deferred-recurly', 'deferred-recurly-remote', 'payment-plan-recurly', 'creditcard-recurly-remote'] as PaymentTypes[]).indexOf(payment.type) === -1 || payment.paidBy === 'CUSTOMER') {
            return true
          }
          let depositPayerInfo: Validation & ValidationProperties<IAddressInfo & IPersonInfo> | undefined
          if (payment.paidBy === 'DEPOSIT_ALT_PAYER') {
            if (this.$v.finance && this.$v.finance.depositAltPayerInfo) {
              depositPayerInfo = this.$v.finance.depositAltPayerInfo
            } else if (this.$v.step2data && this.$v.step2data.depositAltPayerInfo) {
              depositPayerInfo = this.$v.step2data.depositAltPayerInfo
            }
          } else if (payment.paidBy === 'ALTPAYER') {
            if (this.$v.finance && this.$v.finance.altPayerInformation) {
              depositPayerInfo = this.$v.altPayerInformation
            } else if (this.$v.step2data && this.$v.step2data.altPayerInformation) {
              depositPayerInfo = this.$v.step2data.altPayerInformation
            }
          }
          return !depositPayerInfo || !depositPayerInfo.$invalid
        }
      },
      amount: {
        required,
        minValue: function (this: DataTree, dollarValue: number | undefined | null, payment: PaymentWithDollars) {
          return !helpers.req(dollarValue) || dollarValue! >= payment.minAmount
        },
        maxValue: function (this: DataTree, dollarValue: number | undefined | null, payment: PaymentWithDollars) {
          return !helpers.req(dollarValue) || dollarValue! <= payment.maxAmount
        }
      },
      receiptNumber: {
        required: requiredIf((value: Payment) => {
          return value.type === 'exemplarsite-card'
        })
      },
      depositAmountInCents: {
        required: requiredIf((value: Payment) => {
          return (['payment-plan-stripe'] as PaymentTypes[]).indexOf(value.type) !== -1
        }),
        minValue: function (this: DataTree, centsValue: number | undefined | null, payment: StripePaymentPlan) {
          return !helpers.req(centsValue) || centsValue! >= payment.amountInCents * 0.25
        },
        maxValue: function (this: DataTree, centsValue: number | undefined | null, payment: StripePaymentPlan) {
          return !helpers.req(centsValue) || centsValue! <= (payment.amountInCents - 300)
        }
      },
      instalments: {
        required: requiredIf((value: PaymentWithDollars) => {
          return (['payment-plan-stripe'] as PaymentTypes[]).indexOf(value.type) !== -1
        })
      },
      dueOn: {
        required: requiredIf((value: PaymentWithDollars) => {
          return value.deferred
        }),
        minValue: function (this: DataTree, dateStr: string | undefined | null, payment: PaymentWithDollars) {
          return !payment.deferred || !dateStr || moment(dateStr, 'YYYY-MM-DD').isAfter((this.$store.getters as IrisGetters).moment())
        },
        maxValue: function (this: DataTree, dateStr: string | undefined | null, payment: PaymentWithDollars) {
          return !payment.deferred || !dateStr || moment(dateStr, 'YYYY-MM-DD').isBefore((this.$store.getters as IrisGetters)['payments/deferredDepositMaxDate'])
        },
        notOnAWeekend (this: DataTree, dateStr: string | undefined | null, payment: PaymentWithDollars) {
          // see https://momentjs.com/docs/#/get-set/iso-weekday/
          return (['deferred'] as PaymentTypes[]).indexOf(payment.type) === -1 || !dateStr || moment(dateStr, 'YYYY-MM-DD').isoWeekday() <= 5 // monday = 1 - friday = 5
        }
      }
    }
  }
}

export function deposit (this: DataTree): PartRuleTree {
  return {
    monthsDeposit: {
      required: requiredIf(function (this: DataTree) {
        return this.finance.paymentMethod === 'PAYG' && !(this.$store.getters as IrisGetters).paygRevisionPricing
      })
    } // ,
    // deposit: {
    //   required: required(),
    //   decimal: decimal(),
    //   minValue: minValue(1),
    //   maxValue: function (this: DataTree, v: number) {
    //     if (!v) return true
    //     return v <= (this.$store.getters as IrisGetters).saleValue
    //   }
    // }
  }
}

export function term (): PartRuleTree {
  return {
    monthsTerm: {
      required: requiredIf(function (this: DataTree) {
        if (this.finance.paymentMethod === 'LTL' && this.finance.decision === 'DFA') {
          return false
        }
        return (this.$store.getters as IrisGetters).paygRevisionPricing || ['LTL', 'APS'].indexOf(this.finance.paymentMethod) !== -1
      })
    },
    decision: {
      required: requiredIf(function (this: DataTree) {
        return this.finance.paymentMethod === 'LTL'
      })
    }
  }
}

export const children: PartRuleTree = {
  $each: {
    firstName: {
      required,
      maxLength: maxLength(148),
      hasSomeLowerCase,
      hasSomeUpperCase
    },
    lastName: {
      required,
      maxLength: maxLength(48),
      hasSomeLowerCase,
      hasSomeUpperCase
    },
    gender: {
      required
    },
    dob: {
      required,
      maxValue: function (this: DataTree, dateStr?: string) {
        return moment(dateStr, 'YYYY-MM-DD').isBefore((this.$store.getters as IrisGetters).moment())
      },
      minValue: function (this: DataTree, dateStr?: string) {
        return moment(dateStr, 'YYYY-MM-DD').isAfter((this.$store.getters as IrisGetters).moment().subtract(120, 'years'))
      }
    },
    courses: (['maths', 'mathsGCSE', 'mathsN5', 'literacy', 'reading'] as CourseTypes[]).reduce((prev, subject) => {
      prev[subject] = {
        required: requiredIf(function (this: DataTree, _model) {
          return (this.$store.getters as IrisGetters).selectedCoursesLists[subject].length
        })
      }
      return prev
    }, {} as Record<string, Record<string, ValidationRule>>)
  }
}

export const loqateValidator = (responseCallback: (response: LoqateEmailValidationResponse) => void) => {
  return async function (this: DataTree, value: string) {
    if (!helpers.req(value) || !(email as unknown as CustomRule)(value)) return true
    try {
      const response = await this.$loqate.validate(value)
      if (responseCallback) responseCallback.call(this, response)
      return (
        !response.IsDisposableOrTemporary &&
        !response.IsComplainerOrFraudRisk &&
        (response.ResponseCode.indexOf('Valid') === 0 || response.ResponseCode.indexOf('Timeout') === 0)
      )
    } catch (exception) {
      // eslint-disable-next-line no-console
      console.log(exception)
      return true
    }
  }
}

export const ukPostcodeValid = regex('postcode', UK_POSTCODE_REGEX)

export function altPayerValidationsFn ({ loqateKey } : {loqateKey: string}): PartRuleTree {
  return {
    title: {
      required,
      maxLength: maxLength(20)
    },
    firstName: {
      required,
      maxLength: maxLength(148),
      hasSomeLowerCase,
      hasSomeUpperCase
    },
    dob: {
      required: required,
      maxValue: function (this: DataTree, dateStr?: string) {
        return moment(dateStr, 'YYYY-MM-DD').isBefore(
          (this.$store.getters as IrisGetters).moment().subtract(18, 'years')
        )
      },
      minValue: function (this: DataTree, dateStr?: string) {
        return moment(dateStr, 'YYYY-MM-DD').isAfter(
          (this.$store.getters as IrisGetters).moment().subtract(120, 'years')
        )
      }
    },
    lastName: {
      required,
      maxLength: maxLength(48),
      hasSomeLowerCase,
      hasSomeUpperCase
    },
    email: {
      required,
      email,
      loqate: loqateValidator(function (this: DataTree, response) {
        this.loqateMessages[loqateKey] = response.ResponseMessage
      }),
      maxLength: maxLength(128)
    },
    emailConfirm: {
      required,
      sameAsEmail: sameAs('email')
    },
    address1: {
      required,
      maxLength: maxLength(148)
    },
    address2: {
      maxLength: maxLength(148)
    },
    city: {
      required,
      maxLength: maxLength(32)
    },
    postcode: {
      required,
      ukPostcodeValid,
      maxLength: maxLength(16)
    }
  }
}

export const altPayerValidations = altPayerValidationsFn({
  loqateKey: 'altPayerEmail'
})

export function hasSomeLowerCase (input: any): boolean {
  if (typeof input === 'string' && input) {
    return !!input.match(/[a-z]/)
  }
  return true
}

export function hasSomeUpperCase (input: any): boolean {
  if (typeof input === 'string' && input) {
    return !!input.match(/[A-Z]/)
  }
  return true
}
